import { IUserInfo } from "contexts/UserContext";
import dayjs from "dayjs";
import Team from "models/Team";
import { QueryClient } from "react-query";
import Stripe from "stripe";
import {
  AIWarmingScheduleType,
  AppEnvType,
  AppRoleEnum,
  CreationFlow,
  FeatureNames,
  PaginatedResponse,
  UserActivityLog,
} from "types";
import { getQueryStrFormParams } from "utils/getComplexFilters";
import AbstractApi from "./AbstractApi";
import { GenerationPlatform } from "./TvChannelsApi";

export interface ITvGarmentStatistic {
  garmentCount: number;
  stepDate: Date;
}

export type UserStatistics = IUserInfo & {
  subscription: Stripe.Subscription | null;
  teamCount: number;
  invitedBy: string | null;
  createdById: number | null;
  createdBy: IUserInfo | null;
  creationFlow: CreationFlow;
  projectCount: number;
  tvProjectCount: number;
  tvGarmentCount: number;
  imageCredits: number;
  registeredAt: Date;
  lastProjectUpdatedAt: Date;
  lastTVProjectUpdatedAt: Date;
  tvGarmentStatistic: ITvGarmentStatistic[];
  customer: Stripe.Customer | null;
  totalTVGarmentsCount: number;
  teammatesCount: number;
  supplierId?: number;
  stripeUpcomingInvoiceData?: any;
  appRole: AppRoleEnum;
  isEmailVerified?: boolean;
  loggedInAt?: string;
  firstLoggedInAt?: string;
  lastBenchmarkProjectUpdatedAt: string | null;
  productOrdersCreatedCount: number;
  lastProductOrderUpdatedAt: string | null;
  stripeFreeTrialData: {
    trialStart: string | null;
    trialEnd: string | null;
  };
  teams: Array<{
    currentTeam: boolean;
    team: Team;
  }>;
};

export interface IWhitelistedEmail {
  id: number;
  emailPattern: string;
}

export interface IUsersActivityStatistic {
  generatedTVGarments: number[];
  loggedIn: number[];
  registered: number[];
}

export type GenerationPlatformParameter = {
  defaultValue: string | number;
  elementType: string;
  key: string;
  maxValue: number;
  maxValueTitle: string;
  minValue: number;
  minValueTitle: string;
  title: string;
  type: string;
  description?: string;
};

export interface IGenerationOptionPlatform {
  isDefault: boolean;
  isReadyToUse: boolean;
  platform: GenerationPlatform;
  parameters?: GenerationPlatformParameter[];
  title: string;
  version?: string;
  versionName?: string;
  description?: string;
}

export interface IGenerationOption {
  featureName: FeatureNames;
  description: string;
  platforms: IGenerationOptionPlatform[];
}

export interface IGenerationOptionsPayload {
  variables: Array<{
    featureName: FeatureNames;
    value: {
      platform: GenerationPlatform;
      name?: string;
    };
  }>;
}

export interface ICreateWarmingSchedulerPayload {
  timeFrom: string;
  timeTo: string;
  day: string;
  days?: string[];
  type: AIWarmingScheduleType;
}

export interface IUpdateAppEnvPayload {
  key: string;
  value: string;
}
export default class AdminApi extends AbstractApi {
  constructor(
    userToken: string | null,
    teamId: number | null,
    auth: any,
    queryClient: QueryClient,
    portalId?: number
  ) {
    super(userToken, teamId, auth, queryClient, portalId);
  }

  async getWhitelistedEmails(): Promise<{ data: IWhitelistedEmail[] }> {
    return await this.client.get("/whitelisted-emails");
  }

  async addEmailToWhitelist(payload: { emailPattern: string }) {
    return await this.client.post("/whitelisted-emails", payload);
  }

  async removeEmail(id: number) {
    return await this.client.delete(`/whitelisted-emails/${id}`);
  }

  async getGenerationOptions(): Promise<IGenerationOption[]> {
    return await this.client.get("/image-generation/engine-options");
  }

  async updateGenerationOptions(payload: IGenerationOptionsPayload) {
    return await this.client.post("/image-generation/engine-options", payload);
  }

  async getUsersStatistics(
    params: Record<string, any>
  ): Promise<PaginatedResponse<UserStatistics>> {
    let query = getQueryStrFormParams(params);

    return await this.client.get(`/users/statistic${query}`);
  }

  async getUsersActivityStatistics(): Promise<IUsersActivityStatistic> {
    return await this.client.get("/users/activity-statistic");
  }

  async getUsersActivityStatisticsCsv() {
    const date = dayjs(new Date()).format("YYYY-MM-DD");

    return await this.client
      .get("/users/activity-statistic-csv")
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response as any]));
        const link = document.createElement("a");
        link.href = url;
        const fileName = `report-${date}.csv`;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
      });
  }

  async createWarmingScheduler(payload: ICreateWarmingSchedulerPayload) {
    return await this.client.post("/ai-warming-schedules", payload);
  }

  async getWarmingSchedules(params: { startDate: string; endDate: string }) {
    return await this.client.get("/ai-warming-schedules", { params });
  }

  async deleteWarmingScheduler(id: number) {
    return await this.client.delete(`/ai-warming-schedules/${id}`);
  }

  async uploadFile(payload: FormData) {
    return await this.client.post("/files/upload", payload);
  }

  async getAppEnvs(): Promise<AppEnvType[]> {
    return await this.client.get("/app-envs");
  }

  async getAppEnvByName(name: string): Promise<AppEnvType> {
    return await this.client.get(`/app-envs/${name}`);
  }

  async updateAppEnv(payload: IUpdateAppEnvPayload[]) {
    return await this.client.patch("/app-envs", { envs: payload });
  }

  async getAiProvidersStatus() {
    return await this.client.get("/image-generation/ai-providers/status");
  }

  async getUserActivityLogs(
    params: Record<string, string | number>,
    filter?: string
  ): Promise<PaginatedResponse<UserActivityLog>> {
    let query = "";
    Object.entries(params).forEach(([key, value], index) => {
      if (index) {
        query += `&${key}=${value}`;
      } else {
        query += `${key}=${value}`;
      }
    });
    return await this.client.get(`/user-activity-logs?${query}${filter}`);
  }
}
