interface SlateChild {
  text?: string;
  [key: string]: any;
}

interface SlateContent {
  type: string;
  children: SlateChild[];
}

interface DataI18n {
  default: {
    slate: SlateContent[];
  };
}

interface Plugin {
  id: string;
  version: number;
}

interface Cell {
  id: string;
  size: number;
  plugin: Plugin;
  dataI18n?: DataI18n;
  rows: Row[];
  inline: any;
}

interface Row {
  id: string;
  cells: Cell[];
}

interface EditorData {
  id: string;
  cells: Cell[];
}

interface ExtractedContent {
  title?: string;
  description?: string;
  imageUrl?: string;
}

export function extractDataFromTutorial(data: EditorData[]): ExtractedContent {
  let description: string | undefined = undefined;
  let imageUrl: string | undefined = undefined;
  let slateCount = 0;

  for (const row of data) {
    for (const cell of row.cells) {
      if (
        cell.plugin?.id === "ory/editor/core/content/slate" &&
        cell.dataI18n
      ) {
        slateCount++;

        // Only extract the description from the second slate
        if (slateCount === 2) {
          const slateContent = cell.dataI18n.default.slate;
          description = slateContent
            .map((content) =>
              content.children.map((child) => child.text).join(" ")
            )
            .join(" ");
        }
      }

      if (!imageUrl && cell.plugin?.id === "ory/editor/core/content/image") {
        imageUrl = (cell.dataI18n?.default as any).src;
      }

      if (description && imageUrl) {
        break;
      }
    }

    if (description && imageUrl) {
      break;
    }
  }

  return { description, imageUrl };
}

export function extractTitleDataFromTutorial(data: EditorData[]): any {
  let title: string | undefined = undefined;
  let subtitle: string | undefined = undefined;

  const titleList: {
    id: string;
    title: string;
    type: "h3" | "h4";
  }[] = [];

  for (const row of data) {
    for (const cell of row.cells) {
      if (
        cell.plugin?.id === "ory/editor/core/content/slate" &&
        cell.dataI18n
      ) {
        const slateContent = cell.dataI18n.default.slate;

        title = slateContent
          .filter((content) => content.type === "HEADINGS/HEADING-THREE")
          .map((content) =>
            content?.children?.map((child) => child.text).join(" ")
          )
          .join(" ");

        subtitle = slateContent
          .filter((content) => content.type === "HEADINGS/HEADING-FOUR")
          .map((content) =>
            content?.children?.map((child) => child.text).join(" ")
          )
          .join(" ");
      }

      if (title) {
        titleList.push({
          id: cell.id,
          title: title,
          type: "h3",
        });

        title = "";

        break;
      } else if (subtitle) {
        titleList.push({
          id: cell.id,
          title: subtitle,
          type: "h4",
        });

        subtitle = "";

        break;
      }
    }
  }

  return titleList;
}

const extractFaqsFromTutorial = () => {};
