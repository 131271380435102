import { IUserInfo, UserModel } from "contexts/UserContext";
import BillingHistory from "models/BillingHistory";
import { QueryClient } from "react-query";
import { Stripe } from "stripe";
import {
  BillingDebtsType,
  IBillingHistoryInvoiceResponse,
  IPaginateParams,
  PaginatedResponse,
  PlanNames,
  PlanOfferStatuses,
} from "types";
import AbstractApi from "./AbstractApi";

export interface ISpecialPlanOffer {
  id: number;
  adminId: number;
  planName: string;
  userId: number;
  user: IUserInfo;
  admin: IUserInfo;
  assignedAt: string;
  trialStartAt: string;
  trialEndAt: string;
  discountStartAt: string;
  discountEndAt: string;
}

export interface IAssignToUserPayload {
  planName: PlanNames;
  userId: number;
  duration: number;
}

export type PaymentPreview = {
  currentBalance: number;
  total: number;
  amountDue: number;
  endingBalance: number;
  lines: Array<{
    amount: number;
    description: string;
  }>;
};

export interface IBillingPaginatedParams {
  startingAfter?: string;
  endingBefore?: string;
  limit?: number;
}

export interface IPromoCodePayload {
  name: string;
  description?: string;
  expirationDate: Date;
  maximumUsageNumber: number;
  durationInMonths: number;
  forNewUsersOnly: boolean;
  percentOff?: number;
  amountOff?: number;
}

export interface IPaginatedBillingResponse<T> {
  data: T[];
  has_more: boolean;
  has_more_back: boolean;
  has_more_forward: boolean;
}

export type Coupon = {
  amount_off: number | null;
  percent_off: number | string | null;
  created: number;
  duration_in_months: number;
  id: string;
  max_redemptions: number | null;
  times_redeemed: number;
  valid: boolean;
  applies_to: {
    products: string[];
  };
};

export type PromoCode = {
  active: boolean;
  code: string;
  created: number;
  expires_at: number | null;
  id: string;
  max_redemptions: number | null;
  times_redeemed: number;
  coupon: Coupon;
  restrictions: {
    first_time_transaction: boolean;
  };
};

export interface IPlanOfferPayload {
  userId: number;
  planName: string;
  coupon?: {
    durationInMonths: number;
    description: string;
    percentOff: number;
  };
}

export type PlanOffer = {
  admin: IUserInfo;
  adminId: number;
  id: number;
  status: PlanOfferStatuses;
  stripeCoupon: Coupon;
  stripeCouponId: string;
  stripePlan: Stripe.Plan;
  stripePlanId: string;
  user: IUserInfo;
  userId: number;
};

export default class BillingApi extends AbstractApi {
  constructor(
    userToken: string | null,
    teamId: number | null,
    auth: any,
    queryClient: QueryClient,
    portalId?: number
  ) {
    super(userToken, teamId, auth, queryClient, portalId);
  }

  getProducts = (): Promise<Stripe.Product[]> => {
    return this.client.get("/billing/products");
  };

  getProduct = (id: string): Promise<Stripe.Response<Stripe.Product>> => {
    return this.client.get(`/billing/products/${id}`);
  };

  getPayments = (
    params: IBillingPaginatedParams
  ): Promise<IPaginatedBillingResponse<BillingHistory>> => {
    return this.client.get("/billing/payments", { params });
  };

  getInvoice = (id: string): Promise<IBillingHistoryInvoiceResponse> => {
    return this.client.get(`/billing/invoice/${id}`);
  };

  paymentMethods = (): Promise<Stripe.PaymentMethod[]> => {
    return this.client.get("/billing/payment-methods").then((r) => r?.data);
  };

  addPaymentMethod = (pmId: string) => {
    return this.client.post(`/billing/payment-methods/${pmId}`);
  };

  removePaymentMethod = (pmId: string) => {
    return this.client.delete(`/billing/payment-methods/${pmId}`);
  };

  setDefaultPaymentMethod = (id: string): Promise<any> => {
    return this.client.post(`/billing/set-default-payment-method`, {
      paymentMethodId: id,
    });
  };

  createSubscription = (payload: { priceId: string }) => {
    return this.client.post("/billing/subscription", payload);
  };

  getSubscription = (): Promise<Stripe.Response<Stripe.Subscription>> => {
    return this.client.get(`/billing/subscription`);
  };

  getPaymentPreview = (data: any): Promise<PaymentPreview> =>
    this.client.post("/billing/payment-preview", data);

  changeSubscription = (payload: any) => {
    return this.client.patch(`/billing/subscription`, payload);
  };

  cancelSubscription = (): Promise<Stripe.Subscription> => {
    return this.client.delete(`/billing/subscription`);
  };

  continueCanceledSubscription = (): Promise<Stripe.Subscription> => {
    return this.client.post(`/billing/continue-subscription`);
  };

  getPaymentImagesPreview = (payload: { quantity: number }) => {
    return this.client.post("/billing/invoices/images", payload);
  };

  chargeImages = (payload: { invoiceId: string; paymentMethodId?: string }) => {
    return this.client.post("/billing/invoices/images/charge", payload);
  };

  assignToUser = (payload: IAssignToUserPayload) => {
    return this.client.post("/billing/subscription/assign-to-user", payload);
  };

  getSpecialPlanOffers = async (
    params: IPaginateParams
  ): Promise<PaginatedResponse<ISpecialPlanOffer>> =>
    await this.client.get("/billing/plan-offers/history", { params });

  getUpcomingInvoice = async () =>
    await this.client.get("/billing/upcoming-invoice");

  //promo-codes
  getPromoCodes = async (
    params: IBillingPaginatedParams
  ): Promise<IPaginatedBillingResponse<PromoCode>> =>
    await this.client.get("/billing/promo-codes", { params });

  createPromoCode = async (payload: IPromoCodePayload) =>
    await this.client.post("/billing/promo-codes", payload);

  deletePromoCode = async (id: string) =>
    await this.client.delete(`/billing/promo-codes/${id}`);

  setPromoCodeStatus = async (id: string, isActive: boolean) =>
    await this.client.post(`/billing/promo-codes/${id}/set-status`, {
      isActive,
    });

  validatePromoCode = (payload: { name: string }) => {
    return this.client.post("/billing/promo-codes/validate", payload);
  };

  validateRegisteredUserPromoCode = (payload: {
    name: string;
    productId?: string;
  }) => {
    return this.client.post("/billing/promo-codes/validate-for-user", payload);
  };

  createPlanOffer = (payload: IPlanOfferPayload) =>
    this.client.post("/billing/plan-offers", payload);

  getPlanOffers = (
    params: IPaginateParams
  ): Promise<PaginatedResponse<PlanOffer>> =>
    this.client.get("/billing/plan-offers", { params });

  getUserPlanOffers = (): Promise<PlanOffer> =>
    this.client.get("/billing/plan-offers/my");

  sendUpgradeEmail = (message?: string) =>
    this.client.post("/billing/plan-offers/request-plan-offer", { message });

  payDebt = () => this.client.post("/billing/subscription/pay");

  getDebts = (): Promise<BillingDebtsType> =>
    this.client.get("/billing/subscription/debts");
}
