import BigSpinner from "components/atoms/BigSpinner";
import CustomButton from "components/atoms/CustomButton";
import Image from "components/atoms/Image";
import { usePortal } from "contexts/PortalContext";
import { useUser } from "contexts/UserContext";
import { useGetArticles, useGetPublishedArticles } from "hooks/articles";
import { usePrepareInfiniteData } from "hooks/usePrepareInfiniteData";
import Article from "models/Article";
import { useRouter } from "next/router";
import React from "react";
import { extractDataFromTutorial } from "utils/extractDataFromTutorial";
import ExpandIcon from "public/icons/expand_16.svg";
import useTranslation from "next-translate/useTranslation";

interface TutorialGridProps {
  onSelectTutorial: (id: number) => void;
}

const TutorialGrid: React.FC<TutorialGridProps> = ({ onSelectTutorial }) => {
  const { user } = useUser();
  const { push } = useRouter();
  const { portalName } = usePortal();
  const { t } = useTranslation("common");

  const { data, isLoading } = useGetPublishedArticles(
    {},
    {
      enabled: !!user,
    }
  );

  const articles: Article[] = usePrepareInfiniteData(data);

  const parsedArticles = articles.map((item: Article) => ({
    id: item.id,
    title: item.title,
    relatedFeature: item.relatedFeature,
    ...extractDataFromTutorial(item.body.rows as any),
  }));

  const tutorials = parsedArticles.filter(
    (item) => item.relatedFeature !== "FAQs"
  );
  const faqs = parsedArticles.filter((item) => item.relatedFeature === "FAQs");

  const handleMoveToTutorial = () => {
    push(`/${portalName}/tutorial`);
  };

  return (
    <div className="relative w-full h-[calc(100%-52px)]">
      <div className="relative p-4 h-[calc(100%-58px)] w-full overflow-y-scroll">
        {isLoading ? (
          <div className="w-full h-full flex items-center justify-center">
            <BigSpinner className="h-20 w-20" />
          </div>
        ) : (
          <div className="grid grid-cols-1 gap-6">
            {tutorials.map((item) => (
              <div
                key={item.id}
                className="flex flex-col overflow-hidden rounded-2xl border border-border-normal"
              >
                <div
                  className="w-full h-[136px] flex items-center justify-center overflow-hidden bg-gray-20 rounded-t-lg cursor-pointer flex-none"
                  onClick={() => item.id && onSelectTutorial(item.id)}
                >
                  {item.imageUrl && <Image imgUrl={item.imageUrl} />}
                </div>
                <div className="flex flex-col gap-2 p-4 pt-3">
                  <div className="text-base leading-tight font-medium text-typography-headline">
                    {item.title}
                  </div>
                  <div className="text-xs leading-tight font-norml text-typography-body-subtle line-clamp-2">
                    {item.description}
                  </div>
                </div>
              </div>
            ))}
            {faqs.map((item) => (
              <div
                key={item.id}
                className="flex flex-col overflow-hidden rounded-2xl border border-border-normal"
              >
                <div
                  className="w-full h-[136px] flex items-center justify-center overflow-hidden bg-gray-20 rounded-t-lg cursor-pointer flex-none"
                  onClick={() => item.id && onSelectTutorial(item.id)}
                >
                  {item.imageUrl && <Image imgUrl={item.imageUrl} />}
                </div>
                <div className="flex flex-col gap-2 p-4 pt-3">
                  <div className="text-base leading-tight font-medium text-typography-headline">
                    {item.title}
                  </div>
                  <div className="text-xs leading-tight font-norml text-typography-body-subtle line-clamp-2">
                    {item.description}
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="px-4 py-3 w-full bg-white">
        <div className="flex items-center justify-start">
          <CustomButton
            variant="tertiary"
            icon={ExpandIcon}
            viewBox="0 0 16 16"
            className="!p-2"
            iconClassName="!fill-cta-600"
            onClick={() => handleMoveToTutorial()}
          />

          <p
            className="text-sm leading-tight font-medium text-indigo-600 cursor-pointer"
            onClick={() => handleMoveToTutorial()}
          >
            {t("view_tutorial_in_full_screen")}
          </p>
        </div>
      </div>
    </div>
  );
};

export default TutorialGrid;
