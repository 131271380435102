import { useGetCurrentSupplier } from "hooks/supplier";
import { Supplier } from "models/Supplier";
import React from "react";
import { useContext } from "react";
import { useTeam } from "./TeamContext";
import { useUser } from "./UserContext";

interface IInitialValues {
  supplier?: Supplier;
  refetchSupplier: () => void;
  isSupplierFetched: boolean;
  isSupplierExists: boolean;
}

const SupplierContext = React.createContext<IInitialValues>({
  refetchSupplier: () => {},
  isSupplierFetched: false,
  isSupplierExists: false,
});

export const useSupplier = () => useContext(SupplierContext);

const SupplierProvider: React.FC = ({ children }) => {
  const { user, isFetched, subscription } = useUser();
  const { currentTeam } = useTeam();

  const {
    data: supplier,
    refetch: refetchSupplier,
    isFetched: isSupplierFetched,
  } = useGetCurrentSupplier(
    { teamId: currentTeam?.id!, subscription },
    {
      enabled: !!user && !!currentTeam && isFetched,
    }
  );

  const isSupplierExists = !!supplier && isSupplierFetched;

  return (
    <SupplierContext.Provider
      value={{
        supplier,
        refetchSupplier,
        isSupplierFetched,
        isSupplierExists,
      }}
    >
      {children}
    </SupplierContext.Provider>
  );
};

export default SupplierProvider;
