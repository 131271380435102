import React from "react";
import cn from "classnames";
import Icon, { IconProps } from "./Icon";
import { useBreakpoint } from "contexts/BreakpointContext";

export type Variant =
  | "primary"
  | "secondary"
  | "tetriary"
  | "danger"
  | "link"
  | "text"
  | "dark"
  | "dark-tetriary"
  | "dark-text";
export type Size = "lg" | "md" | "sm" | "sq" | "ro";

interface ButtonInterface {
  className?: string;
  variant?: Variant;
  spinner?: boolean;
  size?: Size;
  iconLeft?: any;
  iconLeftProps?: Partial<IconProps>;
  iconRight?: any;
  iconRightProps?: Partial<IconProps>;
  hoverIconColor?: string;
  textClassName?: string;
  capitalize?: boolean;
}

export type ButtonType = ButtonInterface &
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >;

const variantClassNames: Record<Variant, string> = {
  primary:
    "bg-indigo-70 text-white stroke-white hover:bg-indigo-50 active:bg-indigo-30",
  secondary:
    "bg-white text-gray-950 border border-border-normal hover:border-border-hover hover:bg-gray-10 active:bg-gray-20",
  tetriary: "bg-white hover:bg-gray-20 bg-transparent",
  link: "!p-0 !m-0 link-100 font-normal",
  text: "!p-0 !m-0 body-100 hover:text-indigo-70",
  danger: "bg-red-50 text-white stroke-white hover:bg-red-70 active:bg-red-30",
  dark: "bg-gray-950 text-white stroke-white hover:bg-gray-80 active:bg-gray-70",
  "dark-tetriary": "bg-white hover:bg-gray-10 text-title stroke-gray-70",
  "dark-text": "!p-0 !m-0 body-100 text-gray-950",
};

const sizeClassNames: Record<Size, string> = {
  lg: "headline-400 py-[13px]",
  md: "headline-500 py-[11px]",
  sm: "headline-500 py-[7.25px]",
  sq: "",
  ro: "",
};

const CtaButton: React.FC<ButtonType> = ({
  children,
  className,
  variant = "dark",
  spinner,
  iconLeft,
  iconRight,
  iconLeftProps,
  iconRightProps,
  hoverIconColor,
  textClassName,
  type = "button",
  size = "lg",
  ...rest
}) => {
  const { xxs } = useBreakpoint();

  return (
    <button
      type={type}
      className={cn(
        "flex justify-center items-center py-2 px-4 rounded-md group gap-1.5",
        variantClassNames[variant],
        sizeClassNames[xxs ? "sm" : size],
        rest.disabled && !spinner && "!text-gray-50 !bg-gray-30 border-0",
        className
      )}
      {...rest}
      disabled={rest.disabled || spinner}
    >
      {spinner && (
        <svg
          role="status"
          className="inline w-4 h-4 mr-3 text-white animate-spin"
          viewBox="0 0 100 101"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
            fill="#E5E7EB"
          />
          <path
            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
            fill="currentColor"
          />
        </svg>
      )}
      {!!iconLeft && (
        <Icon
          component={iconLeft}
          width={14}
          height={14}
          {...iconLeftProps}
          className={cn("", iconLeftProps?.className)}
        />
      )}
      {children}
      {!!iconRight && (
        <Icon
          component={iconRight}
          width={14}
          height={14}
          {...iconRightProps}
          className={cn("", iconRightProps?.className)}
        />
      )}
    </button>
  );
};

export default CtaButton;
