import ShirtIcon from "public/icons/categories-20/shirt.svg";
import BottomIcon from "public/icons/categories-20/bottom.svg";
import HoodieIcon from "public/icons/categories-20/hoodie.svg";
import OutwearIcon from "public/icons/categories-20/outwear.svg";
import SneakersIcon from "public/icons/categories-20/sneakers.svg";
import CoatIcons from "public/icons/categories-20/coat.svg";
import UnderwearIcon from "public/icons/categories-20/underwear.svg";
import TopIcon from "public/icons/categories-20/top.svg";
import BagsIcon from "public/icons/categories-20/bags-packs.svg";
import KnitwearIcon from "public/icons/categories-20/knitwear.svg";
import DressIcon from "public/icons/categories-20/dress.svg";
import DenimIcon from "public/icons/categories-20/denim.svg";
import ActivewearIcon from "public/icons/categories-20/active-wear.svg";
import SwimwearIcon from "public/icons/categories-20/swimwear.svg";
import SandalsIcon from "public/icons/categories-20/slides-sandals.svg";
import NightwearIcon from "public/icons/categories-20/nightwear.svg";
import BootsIcon from "public/icons/categories-20/boots.svg";
import SkirtIcon from "public/icons/categories-20/skirt.svg";
import HeelsIcon from "public/icons/categories-20/heels.svg";
import SuitIcon from "public/icons/categories-20/suit.svg";
import FormalIcon from "public/icons/categories-20/formal-shoes.svg";
import RunningIcon from "public/icons/categories-20/running-footwear.svg";
import KidsIcon from "public/icons/categories-20/kidswear.svg";
import OutdoorShoesIcon from "public/icons/categories-20/outdoor-shoes.svg";
import CasualIcon from "public/icons/categories-20/casual-footwear.svg";
import SportIcon from "public/icons/categories-20/sport-shoes.svg";
import GeneralIcon from "public/icons/categories-20/general-shoes.svg";
import JumpsuitIcon from "public/icons/categories-20/jumpsuit.svg";
import OutdoorIcon from "public/icons/categories-20/outdoor-wear.svg";
import TrainingIcon from "public/icons/categories-20/training-shoes.svg";
import SetsIcon from "public/icons/categories-20/outfits-suits.svg";
import OtherIcon from "public/icons/categories-20/accessories.svg";
import SkateIcon from "public/icons/categories-20/skate.svg";
import ClassicIcon from "public/icons/categories-20/classic-footwear.svg";
import AthleticIcon from "public/icons/categories-20/athletic-shoes.svg";
import {
  ISortOption,
  RangeType,
  SelectOption,
  SelectOptionsType,
} from "../types";
import { humnaizeDate } from "./humanizeDate";
import dayjs from "dayjs";

export const DASHBOARD_ROUT = "/forecast";

export const HOME_ROUT = "/home";

export const DEFAULT_PORTAL_NAME = "main";

export const HEADER_HEIGHT = 56;

export enum FILTER_OPTIONS {
  Category = "catgory",
  Color = "colorway",
  Material = "material",
}

export enum TrendValue {
  FORECAST = "F",
  HISTORICAL = "H",
}

export enum MarketEnum {
  BRAND = "B",
  RETAILER = "R",
  MARKETPLACE = "M",
  DIGITAL = "D",
  SECONDHAND = "S",
  OUTLET = "O",
  LIFESTYLE_RETAILER = "LR",
}

export const ExploreTrendsSeason = ["SS25", "FW25"];

export const TrendTypeInformation: {
  [key: string]: {
    fullYear: number;
    shortYear: number;
    fullTrendType: string;
    shortTrendType: string;
    shortTrendType1: string;
    filterYear: number;
    trendMonths: number;
  };
} = {
  SS24: {
    fullYear: 2024,
    shortYear: 24,
    fullTrendType: "Spring/ Summer",
    shortTrendType: "SS",
    shortTrendType1: "S/S",
    filterYear: 1,
    trendMonths: 12,
  },
  FW24: {
    fullYear: 2024,
    shortYear: 24,
    fullTrendType: "Fall/ Winter",
    shortTrendType: "FW",
    shortTrendType1: "F/W",
    filterYear: 1,
    trendMonths: 18,
  },
  SS25: {
    fullYear: 2025,
    shortYear: 25,
    fullTrendType: "Spring/ Summer",
    shortTrendType: "SS",
    shortTrendType1: "S/S",
    filterYear: 2,
    trendMonths: 24,
  },
  FW25: {
    fullYear: 2025,
    shortYear: 25,
    fullTrendType: "Fall/ Winter",
    shortTrendType: "FW",
    shortTrendType1: "F/W",
    filterYear: 2,
    trendMonths: 30,
  },
  SL25: {
    fullYear: 2025,
    shortYear: 25,
    fullTrendType: "Seasonless",
    shortTrendType: "SL",
    shortTrendType1: "S/L",
    filterYear: 2,
    trendMonths: 30,
  },
};

export enum ExploreTrendsSortByOptions {
  GHL = "GHL",
  DHL = "DHL",
  GLH = "GLH",
  DLH = "DLH",
}

export const TrendTypes = [
  { name: "Spring/ Summer", value: "SS" },
  { name: "Fall/ Winter", value: "FW" },
];

export const TrendTypeFullNames: { [key: string]: string } = {
  ss: "SS",
  fw: "FW",
  seasonless: "Seasonless",
};

export const ForecastTrendTypes = [
  { name: "Spring/Summer 2024", value: "SS24" },
  { name: "Fall/Winter 2024", value: "FW24" },
  { name: "Spring/Summer 2025", value: "SS25" },
  { name: "Fall/Winter 2025", value: "FW25" },
];

export const BrandColorList = [
  "#00579C",
  "#1A0E7D",
  "#4832FA",
  "#7A1FA2",
  "#512DA7",
  "#9A8DFC",
  "#7E57C2",
  "#EF6C00",
  "#BF360C",
  "#78909C",
  "#465A65",
  "#8C6E63",
  "#5D4038",
  "#EC407A",
  "#C2175B",
  "#0288D1",
  "#0098A6",
  "#00887A",
  "#004C3F",
  "#689F39",
  "#33691E",
];

export const ColorNames = [
  { name: "Aqua", value: "aqua" },
  { name: "Beige", value: "beige" },
  { name: "Black", value: "black" },
  { name: "Blue", value: "blue" },
  { name: "Brown", value: "brown" },
  { name: "Clear", value: "clear" },
  { name: "Gold", value: "gold" },
  { name: "Gray", value: "gray" },
  { name: "Green", value: "green" },
  { name: "Lime", value: "lime" },
  { name: "Maroon", value: "maroon" },
  { name: "Multicolor", value: "multicolor" },
  { name: "Navy", value: "navy" },
  { name: "Olive", value: "olive" },
  { name: "Orange", value: "orange" },
  { name: "Peach", value: "peach" },
  { name: "Pink", value: "pink" },
  { name: "Purple", value: "purple" },
  { name: "Red", value: "red" },
  { name: "Silver", value: "silver" },
  { name: "Teal", value: "teal" },
  { name: "White", value: "white" },
  { name: "Yellow", value: "yellow" },
];

export const Colors: {
  [key: string]: string;
} = {
  aqua: "#669DDD",
  beige: "#DEC19E",
  black: "#000000",
  blue: "#46A9FC",
  brown: "#A37059",
  clear: "#F4FAFC",
  gold: "#FFD42C",
  gray: "#808080",
  green: "#17A837",
  lime: "#C1E344",
  maroon: "#800000",
  navy: "#000080",
  olive: "#808000",
  orange: "#FFA500",
  peach: "#FAB7AA",
  pink: "#FF69B4",
  purple: "#AA7FE9",
  red: "#FF0000",
  teal: "#008080",
  white: "#FFFFFF",
  yellow: "#FDD530",
  multicolor:
    "linear-gradient(rgb(226, 64, 155) 0%, rgb(95, 83, 218) 51.04%, rgb(121, 204, 198) 86.46%)",
  silver:
    "linear-gradient(rgb(201, 201, 201) 25.52%, rgb(255, 255, 255) 48.44%, rgb(201, 201, 201) 84.37%)",
};

export const colorOptions = Object.entries(Colors).map(([key, value]) => ({
  name: key,
  value,
}));

export enum Gender {
  F = "Women",
  M = "Men",
  U = "Unisex",
  K = "Kids",
  B = "Boys",
  G = "Girls",
  Y = "Baby",
}

export enum Catgroup {
  C = "Clothing",
  F = "Footwear",
  B = "Bags",
  O = "Other",
  Y = "Baby",
}

// Options
export enum TrendFilterGender {
  F = "Women",
  M = "Men",
}

export enum ExploreTrendsGender {
  F = "Women",
  M = "Men",
}

export enum TrendFilterCatgroup {
  C = "Clothing",
  F = "Shoes",
  B = "Bags",
}

export enum BenchmarkSetupField {
  MARKET = "market",
  REGION = "region",
  RETAILER = "retailer",
  CATEGORY = "category",
  GENDER = "gender",
  COLOR = "color",
  MATERIAL = "material",
}

export enum AdditionalProducts {
  PROJECTS = "projects",
  MEMBERS = "teamMembers",
  IMAGES = "imageCredits",
}

export enum BenchmarkDetailsNavs {
  TREND_ANALYSIS = "market-trends",
  COLLECTION_ANALYSIS = "collection-analysis",
  ACTIONABLE_ADVISE = "actionable-advise",
}

export const PriceRangeTitle: { [key: string]: any } = {
  "0-100": "$0 - $100",
  "100-200": "$100 - $200",
  "200-300": "$200 - $300",
  "300-400": "$300 - $400",
  "400-500": "$400 - $500",
  "500-600": "$500 - $600",
  "600-700": "$600 - $700",
  "700-800": "$700 - $800",
  "800-900": "$800 - $900",
  "900-1000": "$900 - $1000",
  "1000+": "$1000+",
  missing_price: "Missing Price",
};

// local storage keys
export const USER_EMAIL = "user-email";
export const TRENDS_TUTORIAL_KEY = "trends-tutorial";
export const TRENDS_DETAILS_TUTORIAL_KEY = "trends-details-tutorial";
export const ANALYTICS_TUTORIAL_KEY = "projects-tutorial";
export const ANALYTICS_DETAILS_TUTORIAL_KEY = "project-details-tutorial";
export const AI_STREAMING_TUTORIAL_KEY = "ai-streaming-tutorial";
export const AI_FILTERS_TUTORIAL_KEY = "ai-filters-tutorial";
export const AI_BENCHMARKING_TUTORIAL_KEY = "ai-benchmarking-tutorial";
export const ORDER_FILTERS = "order-filters";
export const ORDER_FILTERS_TECH_PACK = "order-filters-tech-pack";
export const ORDER_VIEW = "order-view";
export const ARTICLES_VIEW = "articles-view";
export const USER_ARTICLES_VIEW = "tutorial-view";

export const sortOptions: Array<ISortOption> = [
  {
    name: "recent_first",
    value: { field: "updatedat", direction: "DESC" },
    sectionSplitter: (entity) =>
      humnaizeDate(dayjs(entity.updatedat).format("D MMMM YYYY")),
  },
  {
    name: "older_first_sort",
    value: { field: "updatedat", direction: "ASC" },
    sectionSplitter: (entity) =>
      humnaizeDate(dayjs(entity.updatedat).format("D MMMM YYYY")),
  },
  {
    name: "name_a-z",
    value: { field: "name", direction: "ASC" },
    sectionSplitter: (entity) => entity.name.charAt(0).toUpperCase(),
  },
  {
    name: "name_z-a",
    value: { field: "name", direction: "DESC" },
    sectionSplitter: (entity) => entity.name.charAt(0).toUpperCase(),
  },
];

export const LANGUAGES = ["en", "ko"];

export const BRANDS_COMPARISON_LIMIT = 4;

export const DrawerRootId = "drawers-container";

export const MAX_PRICE_VALUE = 1000000;

export const HIGHEST_PRICE_KEY = "HIGHEST_PRICE";
export const MARKDOWN_PRICE_KEY = "MARKDOWN";

export const categoriesIconsMap: { [key: string]: any } = {
  shirts: ShirtIcon,
  bottoms: BottomIcon,
  sweatshirts_hoodies: HoodieIcon,
  outerwear: OutwearIcon,
  fw_sneakers: SneakersIcon,
  coats_jackets: CoatIcons,
  underwear: UnderwearIcon,
  other: OtherIcon,
  tops: TopIcon,
  bags: BagsIcon,
  knitwear: KnitwearIcon,
  dresses: DressIcon,
  fw_classics: ClassicIcon,
  denim: DenimIcon,
  activewear: ActivewearIcon,
  swimwear: SwimwearIcon,
  fw_slides_sandals: SandalsIcon,
  nightwear: NightwearIcon,
  fw_boots: BootsIcon,
  skirts: SkirtIcon,
  fw_heels: HeelsIcon,
  suits: SuitIcon,
  fw_formal: FormalIcon,
  fw_running: RunningIcon,
  baby: KidsIcon,
  fw_outdoor: OutdoorShoesIcon,
  fw_casual: CasualIcon,
  fw_sport: SportIcon,
  fw_general: GeneralIcon,
  jumpsuits: JumpsuitIcon,
  outdoor: OutdoorIcon,
  fw_training: TrainingIcon,
  sets: SetsIcon,
  fw_skate: SkateIcon,
  fw_athletic: AthleticIcon,
};

export const styleEnumiration = {
  "back-opening": [
    "back button",
    "zip fastening",
    "wrap",
    "crossover shoulder straps",
    "open back",
    "tie fastening",
    "hook and eye",
    "backless",
  ],
  composition: [
    "alpaca",
    "bamboo",
    "cashmere",
    "cotton",
    "cotton-blend",
    "denim",
    "down",
    "elastane",
    "hemp",
    "lambskin",
    "leather",
    "linen",
    "lurex",
    "lycra",
    "lyocell",
    "merino",
    "mohair",
    "nylon",
    "polyamide",
    "polyester",
    "rayon",
    "sheepskin",
    "silk",
    "tencel",
    "viscose",
    "wool",
  ],
  cuff: [
    "banded cuff",
    "button cuff",
    "draped cuff",
    "flared cuff",
    "fringed cuff",
    "gathered cuff",
    "piped cuff",
    "raw edge",
    "ribbed cuff",
    "shell edge",
    "shirt cuff",
    "slit cuff",
    "tied cuff",
  ],
  highlights: [
    "spaghetti straps",
    "draped front panel",
    "stone embellishment",
    "feather trim",
    "glitter logo",
    "piped trims",
    "slit pocket",
    "patch pocket",
    "zip pocket",
    "side slit",
    "whipstitch",
    "reversible",
    "adjustable straps",
    "five pocket design",
  ],
  hemline: [
    "asymmetric hem",
    "banded hem",
    "curved hem",
    "embroidered",
    "flared hem",
    "fringed edge",
    "gathered",
    "high-low",
    "raw edge",
    "shell edge",
    "shirt edge",
    "straight hem",
    "tapered hem",
  ],
  fabrication: [
    "arctic-tech",
    "boucle",
    "canvas",
    "chiffon",
    "corduroy",
    "crepe",
    "crochet",
    "crystal",
    "cupro",
    "denier",
    "econyl",
    "embroidered",
    "faux",
    "felt",
    "fil coupe",
    "flannel",
    "fleece",
    "fur",
    "gabardine",
    "glitter",
    "gore-tex",
    "hairy",
    "jersey",
    "knitted",
    "lace",
    "lurex",
    "mesh",
    "metallic",
    "neoprene",
    "organic",
    "organza",
    "padded",
    "pique",
    "pleated",
    "plisse",
    "poplin",
    "puffer",
    "quilted",
    "ribbed",
    "rubberized",
    "ruffle",
    "satin",
    "seersucker",
    "sequin",
    "shearling",
    "stretch",
    "terry",
    "tulle",
    "tweed",
    "twill",
    "velour",
    "velvet",
    "woven",
  ],
  fit: [
    "slim fit",
    "oversize fit",
    "regular fit",
    "relaxed fit",
    "loose fit",
    "stretch",
    "fitted",
  ],
  "front-opening": [
    "single breasted",
    "double breasted",
    "front button",
    "buckle",
    "hook and eye",
    "lacing",
    "open front",
    "tie fastening",
    "toggle open",
    "wrap",
    "zip fastening",
  ],
  neckline: [
    "regular",
    "v-neck",
    "crew neck",
    "u neck",
    "turtleneck",
    "mockneck",
    "asymmetric neck",
    "boat neck",
    "cowl neck",
    "double layer neck",
    "funnel-neck",
    "high neck",
    "halterneck",
    "hooded",
    "lapel",
    "peter pan collar",
    "polo",
    "roll neck",
    "round neck",
    "sailor neck",
    "scarf neck",
    "scoop neck",
    "shawl collar",
    "square neck",
    "stand collar",
    "sweatheart neck",
    "tailored",
    "tube neck",
    "wide neck",
  ],
  waist: [
    "waistband",
    "belted",
    "elastic",
    "drawstring",
    "mid-rise",
    "high-rise",
  ],
  shoulder: [
    "regular shoulder",
    "cold shoulder",
    "dolman shoulder",
    "drop shoulder",
    "off shoulder",
    "one shoulder",
    "puff shoulder",
    "reglan shoulder",
    "saddle shoulder",
    "square shoulder",
  ],
  sleeve: [
    "short sleeve",
    "long sleeve",
    "sleeveless",
    "3 quarter sleeve",
    "cap sleeve",
    "mid sleeve",
  ],
  trim: [
    "beaded trims",
    "buckle",
    "button",
    "chain",
    "eyelets",
    "feather fringe",
    "fur-trimmed",
    "grosgrain",
    "herringbone",
    "lace",
    "leather tape",
    "ribbon",
    "rivets",
    "sequinned",
    "snap button",
    "studded",
    "zip",
  ],
  pattern: [
    "applique",
    "argyle",
    "asymmetric",
    "bleach",
    "camouflage",
    "checked",
    "colour-block",
    "crinkled",
    "crochet",
    "degrade",
    "distressed",
    "embroidered",
    "embellished",
    "floral",
    "geometric",
    "gingham",
    "graffiti",
    "graphic",
    "gradient",
    "hawaiian",
    "hearts",
    "holiday",
    "houndstooth",
    "intarsia",
    "jacquard",
    "knit",
    "logo",
    "monogram",
    "mosaic",
    "nautical",
    "paisley",
    "panelled",
    "patchwork",
    "pinstripe",
    "plaid",
    "polka dot",
    "print",
    "star-print",
    "striped",
    "textured",
    "tropical",
    "zigzag",
    "tie-dye",
    "space-dye",
    "dip-dye",
    "leopard-print",
    "tiger-print",
    "cheetah-print",
    "crocodile-print",
    "giraffe-print",
    "cat-print",
    "snake-print",
    "horse-print",
    "swallow-print",
    "animal-print",
    "butterfly-print",
  ],
};

export const BreakdownChartDateRange = [
  { name: "2Y", value: 24 },
  { name: "1Y", value: 12 },
  { name: "6M", value: 6 },
  { name: "3M", value: 3 },
];

export const BreakdownChartDotColors = [
  "#873800",
  "#D46B08",
  "#FFA940",
  "#FFD591",
  "#FFF7E6",
];

export const CatgroupDefaultValue: SelectOption = {
  name: "Clothing",
  value: "C",
};

export const SubcatgroupDefaultValue: SelectOption = {
  name: "all_subcategories",
  value: "",
};

export const StylesDefaultValue: SelectOption = {
  name: "All styles",
  value: "",
};

export const MaterialDefaultValue: SelectOption = {
  name: "All materials",
  value: "",
};

export const MaterialColors = [
  "#EA855B",
  "#481B5B",
  "#A2C7EB",
  "#97366C",
  "#F4D181",
];

export const BrandColors = [
  "#EA855B",
  "#481B5B",
  "#A2C7EB",
  "#97366C",
  "#F4D181",
];

export const defaultRangeValue: RangeType = { min: 0, max: 0 };

export enum TrendTypeTabs {
  Material = "material",
  Color = "color",
  Brand = "brand",
}

export enum Seasons {
  Summer = "Summer",
  Winter = "Winter",
  Spring = "Spring",
  Fall = "Fall",
}

export enum PlanNames {
  Basic = "Basic plan",
  Premium = "Premium plan",
  Enterprise = "Enterprise plan",
}

export enum AIProjectSortOption {
  Alphabetical = "alphabetical",
  LastEdited = "last_edited",
  DateCreated = "date_created",
}

export enum PaintDefaultColor {
  MaskColor = "rgba(52, 27, 249, 0.5)",
  BrushColor = "rgba(52, 27, 249)",
}

export enum SortModoValues {
  DATE_DESC = "date:desc",
  DATE_ASC = "date:asc",
  NAME_DESC = "name:desc",
  NAME_ASC = "name:asc",
}

export const Category = [
  "activewear",
  "bottoms",
  "coats_jackets",
  "denim",
  "dresses  ",
  "jumpsuits",
  "knitwear",
  "nightwear",
  "outdoor",
  "sets",
  "shirts",
  "skirts",
  "sweatshirts_hoodies",
  "swimwear",
  "tops",
  "underwear",
];

// demo
// forecasting trend data
export const forecastingTrendChartCategories = [
  "2022/2",
  "2022/3",
  "2022/4",
  "2022/5",
  "2022/6",
  "2022/7",
  "2022/8",
  "2022/9",
  "2022/10",
];
export const forecastingTrendData = [30, 40, 45, 50, 49, 60, null, null, null];
export const forecastingData = [null, null, null, null, null, 60, 70, 91, 91];
export const tempPriceData = [30, 40, 45, 50, 49, 60, 70, 91, 84];

export enum WEB_SOCKETS_EVENTS {
  CONNECT = "connect",
  DISCONNECT = "disconnect",
  NEW_COMMENT = "new-comment",
  NEW_MENTION = "new-mention",
  NEW_MESSAGE = "message-created",
  REMOVED_MESSAGE = "message-removed",
  UPDATED_MESSAGE = "message-updated",
  ROOM_CREATED = "room-created",
  ROOM_REMOVED = "room-removed",
  ROOM_UPDATED = "room-updated",
  ROOM_USERS_UPDATED = "room-users-updated",
  ORDER_CREATED = "order-created",
  ORDER_REMOVED = "order-removed",
  ORDER_UPDATED = "order-updated",
  ORDER_REQUEST_CREATED = "order-request-created",
  ORDER_REQUEST_UPDATED = "order-request-updated",
  ORDER_PROPOSAL_CREATED = "order-proposal-created",
  ORDER_PROPOSAL_REMOVED = "order-proposal-removed",
  ORDER_PROPOSAL_UPDATED = "order-proposal-updated",
  GARMENT_CREATED = "tv-garment-created",
  GARMENT_UPDATED = "tv-garment-updated",
  GARMENT_REMOVED = "tv-garment-removed",
  REPLICATE_STATUS_CHANGED = "ai-providers-status-changed",
  DOMAIN_UPDATED = "domain-updated",
  CONNECT_ERROR = "connect_error",
}

export enum CommentsEmailNotificationOption {
  NO_NOTIFICATIONS = "no-notifications",
  ALL_NOTIFICATIONS = "all-notifications",
  MENTIONS_ONLY = "mentions-only",
  REPLIES_ONLY = "replies-only",
  MENTIONS_AND_REPLIES = "mentions-and-replies",
}

export enum CommentSubject {
  GARMENT = "garment",
  TV_PROJECT = "tv-project",
  TV_REVISION = "revision",
  PROJECT = "project",
  PROJECT_BRAND_OVERVIEW = "project-brand-overview",
  PROJECT_PRICE_PRODUCTS = "project-price-products",
  PROJECT_COLORS_MATERIALS = "project-colors-materials",
  PROJECT_OVERVIEW_ABOUT = "project-overview-about",
  PROJECT_OVERVIEW_ONLINE_MARKETPLACE = "project-overview-online-marketplace",
  PROJECT_OVERVIEW_RESTOCK = "project-overview-restock",
  PROJECT_OVERVIEW_TOP_CATEGORIES = "project-overview-top-categories",
  PROJECT_OVERVIEW_TOP_THEME = "project-overview-top-theme",
  PROJECT_OVERVIEW_TOP_COLORS = "project-overview-top-colors",
  PROJECT_OVERVIEW_TOP_MATERIALS = "project-overview-top-materials",
  PROJECT_ACTIVITY_INSTAGRAM = "project-activity-instagram",
  PROJECT_ACTIVITY_SEARCH = "project-activity-search",
  PROJECT_SIMILAR_BRANDS = "project-similar-brands",
  PROJECT_SIMILAR_CATEGORIES = "project-similar-categories",
  PROJECT_SUBCATEGORIES = "project-subcategories",
  TV_CHANNEL = "tv-channel",
  TRENDS_UPWARD = "trends-upward",
  TRENDS_DOWNWARD = "trends-downward",
  TRENDS_NAVIGATOR = "trends-navigator",
  TRENDS_GROWTH = "trends-growth",
  TRENDS_DECLINED = "trends-declined",
  TRENDS_OTHER = "trends-other",
  BENCHMARK_STYLE_TRENDS_OVERVIEW = "benchmark-style-trends-overview",
  BENCHMARK_STYLE_TRENDS_COMPARISON = "benchmark-style-trends-comparison",
  BENCHMARK_STYLE_DETIALS_ANALYSIS = "benchmark-style-details-analysis",
  BENCHMARK_STYlE_PREDICTED_MARKET_GROWTH = "benchmark-style-predicted-market-growth",
  BENCHMARK_STYLE_MAIN_BRAND = "benchmark-style-main-brand",
  BENCHMARK_STYlE_DISTRIBUTION_PRODUCTS_EACH_BRAND = "benchmark-style-distribution-products-each-brand",
  BENCHMARK_STYlE_BREAKDOWN_DETAILS = "benchmark-style-breakdown-details",
  BENCHMARK_STYlE_REPORT_INSIGHT_DETAILS = "benchmark-style-report-insight-details",
  BENCHMARK_STYlE_REPORT_RETAIL_PRICE = "benchmark-style-report-retail-price",
  BENCHMARK_STYlE_REPORT_RETAILER_DISTRIBUTION = "benchmark-style-report-retailer-distribution",
  BENCHMARK_BREAKDOWN_DETAIL_PREFIX = "benchmark-breakdown-detail",
  BENCHMARK_BREAKDOWN_OVERVIEW = "benchmark-breakdown-overvew",
  BENCHMARK_BREAKDOWN_RETAILER_DETAIL = "benchmark-breakdown-detail-retailer",
  BENCHMARK_BREAKDOWN_MATERIAL_DETAIL = "benchmark-breakdown-detail-material",
  BENCHMARK_BREAKDOWN_COLOR_DETAIL = "benchmark-breakdown-detail-color",
  BENCHMARK_BREAKDOWN_PRICING_DETAIL = "benchmark-breakdown-detail-pricing",
  BENCHMARK_CHANGE_CURRENT_DESIGN = "benchmark-change-current-design",
}

export enum CommentPage {
  TV = "ai-design",
  PROJECT = "analytics",
  TRENDS = "trend-forecast",
  BENCHMARK = "benchmark",
}

export const SUPPORT_EMAIL = "support@jellistudio.com";

export const DEFAULT_WELCOME_IMAGE =
  "https://s3.us-west-1.wasabisys.com/ml-image-output-sd/d8a9e32b-d63c-46e0-b65e-85a2830e12b3.png";

export const DEFAULT_AUTH_IMAGE =
  "https://s3.us-west-1.wasabisys.com/ml-image-output-sd/aaea8fcd-88a5-425e-8f7f-b6df2a5c67d9/login-bg.png";

export const MaterialAbbreviateList: { [key: string]: string } = {
  tpu: "thermoplastic polyurethane",
  tpo: "polyolefin",
  tpe: "thermoplastic copolyesters",
  tps: "styrene",
  tpr: "thermoplastic rubber",
  eva: "ethylene vinyl acetate",
  abs: "acrylonitrile butadiene styrene",
  pbt: "polybutylene terephthalate",
};

export const REPLICATE_WARM_UP_TIME = "REPLICATE_WARM_UP_TIME";
export const BASETEN_WARM_UP_TIME = "BASETEN_WARM_UP_TIME";

export const STRIPE_PAYMENT_METHOD_ERROR =
  "There is no `default_payment_method` set on this Customer or Invoice. Set a default on one of those objects, or specify the Payment Method you wish to use in the `payment_method` parameter.";

export enum ArticleAnchors {
  HOME = "home",
  AI_PROJECTS = "ai-projects",
  DOMAINS = "domains",
}

export const INTERNAL_DOMAINS = [
  "dev.jelli.studio",
  "staging.jelli.studio",
  "jelli.studio",
  "localhost:3000",
];

export enum PageNames {
  HOME = "home",
  AI_STREAMING = "ai-streaming",
  AI_DESIGN = "ai-design",
  MESSENGER = "messenger",
  ORDERS = "orders",
  MEDIA_LIB = "media-lib",
  USER_ARTICLES = "tutorial",
  VISUAL_SEARCH = "visual-search",
  TREND_DASHBOARD = "trend-dashboard",
  ANALYTICS = "analytics",
  BENCHMARK = "benchmark",
  REPORTS = "report",
  SUPPORT = "admin/support",
  ARTICLES = "admin/articles",
  ADD_EMAIL = "admin/add-email",
  GENERATION_SETTINGS = "admin/generation-settings",
  PROMO_CODES = "admin/promo-codes",
  PLAN_OFFERS = "admin/plan-offers",
  TRIAL_OFFERS = "admin/trial-offers",
  USER_STATISTICS = "admin/users-statistics",
  INTEGRATIONS = "integrations",
  FORECASTS = "forecast",
  ADMIN_ORDERS = "admin/order-statistics",
}

export const AdminPageNames = [
  PageNames.SUPPORT,
  PageNames.ARTICLES,
  PageNames.ADD_EMAIL,
  PageNames.GENERATION_SETTINGS,
  PageNames.PROMO_CODES,
  PageNames.PLAN_OFFERS,
  PageNames.TRIAL_OFFERS,
  PageNames.USER_STATISTICS,
];

export const MorePageNames = [
  PageNames.TREND_DASHBOARD,
  PageNames.VISUAL_SEARCH,
  PageNames.ANALYTICS,
];

export enum DomainStatuses {
  PROVISIONING = "provisioning",
  ACTIVE = "active",
}

export const EMPTY_DATA = "N/A";

export const durations = [
  { name: "Unlimited", value: 0 },
  { name: "1 month", value: 1 },
  { name: "2 months", value: 2 },
  { name: "3 months", value: 3 },
  { name: "6 months", value: 6 },
  { name: "Year", value: 12 },
];

export const CREATED_SORT_ASC = "createdat:ASC";
export const CREATED_SORT_DESC = "createdat:DESC";

export const IMAGE_URL_TO_ORDER_KEY = "image-url-to-order";

export const MAX_FILE_SIZE = 250000000;
export const MAX_IMAGE_SIZE = 10000000;

export const seasonOptions = [
  { name: "Seasonless", value: "Seasonless" },
  ...ForecastTrendTypes,
];

export const IMAGE_FORMATS = [".png", ".jpeg", ".jpg", ".tiff", ".webp"];
export const FILE_FORMATS = [
  ".pdf",
  ".doc",
  ".docx",
  ".xls",
  ".xlsx",
  ".ppt",
  ".pptx",
  ".rtf",
  ".txt",
  ".md",
];

export const Textiles = ["fabrication", "pattern"];

export enum ReportsTypeEnum {
  RESTOCKS = "restocks",
  SELLOUT = "sellout",
  MARKDOWN = "markdown",
  NEWDROPS = "newdrops",
}

export const possiblyTestEmails = [
  "hemillerjulie",
  "jmhemiller",
  "Juliehbrand",
  "@yojji",
  "jellibean",
  "bingc876",
  "monicagaller791",
  "carrybradshow545",
  "ross79533",
  "cavinmalone241",
  "ceperakuc",
  "burdinbogdan",
  "kartofanfri",
  "zamesbars",
  "jellistudio",
  "teddan",
  "michaelmanager29",
];

export const SUPPLIER_TERMS_CONDITIONS_LINK =
  "https://www.jellistudio.com/terms-conditions";

export enum OpenModalEnum {
  OPEN_BENCHMARK = "open-benchmark",
  OPEN_HISTORY = "open-history",
  OPEN_FORECASTS = "open-forecasts",
}

export const pageViewOptions: SelectOptionsType = [
  { name: "5", value: 5 },
  { name: "10", value: 10 },
  { name: "20", value: 20 },
  { name: "50", value: 50 },
];

export const PO_BASIC_FIELDS = [
  "title",
  "sku",
  "currency",
  "expectedPricePerUnit",
  "expectedShipDate",
  "season",
];

export const PO_SIZES_FIELDS = ["sizes", "sizesFiles"];

export const PO_FABRIC_FIELDS = ["fabrics"];

export const PO_DELIVERY_FIELDS = [
  "deliveryAddress",
  "deliveryContact",
  "deliveryCity",
  "deliveryZipCode",
  "deliveryContactName",
  "deliveryState",
  "deliveryCountry",
];

export const PO_MAIN_IMAGE_FIELDS = ["productFiles"];

export const PO_CATEGORY_FIELDS = [
  "gender",
  "catgroup",
  "basecat",
  "subcat",
  "color",
];

export const PO_BASIC_ID = "po-basic-form-block";
export const PO_IMAGE_ID = "po-image-form-block";
export const PO_SIZE_ID = "po-size-form-block";
export const PO_FABRIC_ID = "po-fabric-form-block";
export const PO_DELIVERY_ID = "po-delivery-form-block";
export const PO_CATEGORY_ID = "po-category-form-block";

export const OrderStatusColor: {
  [key: string]: string;
} = {
  pending: "#FFA940",
  accepted: "#73D13D",
  rejected: "#FF4D4F",
  cancelled: "#BFBFBF",
};

export const JELLI_NAME = "jelli.studio";

export const serviceOptions = [
  { name: "Fabric Mills", value: "Fabric Mills" },
  { name: "R&D Design", value: "R&D Design" },
  { name: "3D Product Design", value: "3D Product Design" },
  { name: "Factories/ Production", value: "Factories/ Production" },
  { name: "Garment Wash", value: "Garment Wash" },
  { name: "Screen Printing", value: "Screen Printing" },
  { name: "Quality Assurance", value: "Quality Assurance" },
  { name: "Cut & Sew", value: "Cut & Sew" },
  { name: "Knitted Textiles", value: "Knitted Textiles" },
  { name: "Wovens", value: "Wovens" },
  { name: "Knits", value: "Knits" },
];

export const genderOptions = Object.entries(Gender).map(
  ([key, value]: string[]) => ({
    name: value,
    value: key,
  })
);

export const businessModelOptions = [
  { name: "ODM", value: "ODM" },
  { name: "OEM", value: "OEM" },
];

export const currencyOptions = [{ name: "USD", value: "usd" }];

export const shippingMethodOptions = [
  { name: "DDP", value: "DDP" },
  { name: "FOB", value: "FOB" },
];

export const SUPPLIER_FORM_TABS = {
  BASIC: "Basic Information",
  PAYMENT: "Payment Terms",
  GALLERY: "Gallery",
  PRODUCTION: "Product and Production",
  FINANCE: "Financial Options",
};

export const SUPPLIER_BASIC_FIELDS = [
  "location",
  "service",
  "description",
  "businessModel",
  "monthlyOutput",
  "grossAnnualSalesCurrency",
  "grossAnnualSales",
  "productionLeadTime",
  "moq",
  "shippingMethod",
  "rdStuffCount",
  "qaStuffCount",
  "employeeCount",
  "certificationFiles",
];

export const SUPPLIER_NAME_ERROR =
  "name must be longer than or equal to 4 characters, name must be a string";

export const FIREBASE_URL = "https://identitytoolkit.googleapis.com";

export const CHINA = "CN";

export const EMPTY_DASH = "–";

export const PORTAL_SELECTION_WIDTH = 52;

export const PORTAL_SELECTION_AND_NAV_WIDTH_EXPANDED = 262;

export const MOBILE_BREAKPOINT = 480;

export const MONTHS = [
  {
    name: "January",
    value: "01",
  },
  {
    name: "February",
    value: "02",
  },
  {
    name: "March",
    value: "03",
  },
  {
    name: "April",
    value: "04",
  },
  {
    name: "May",
    value: "05",
  },
  {
    name: "June",
    value: "06",
  },
  {
    name: "July",
    value: "07",
  },
  {
    name: "August",
    value: "08",
  },
  {
    name: "September",
    value: "09",
  },
  {
    name: "October",
    value: "10",
  },
  {
    name: "November",
    value: "11",
  },
  {
    name: "December",
    value: "12",
  },
];
