import React from "react";
import { Switch } from "@headlessui/react";
import cn from "classnames";
import BigSpinner from "./BigSpinner";
import Tooltip from "./Tooltip";

export interface IToggleSwitchProps {
  label?: string;
  rightLabel?: string;
  className?: string;
  checked: boolean;
  onChange: any;
  disabled?: boolean;
  loading?: boolean;
  labelClassName?: string;
  containerClassName?: string;
  innerClassName?: string;
  tip?: string;
  hideDefaultLabel?: boolean;
}

const ToggleSwitch = ({
  className,
  label,
  rightLabel,
  checked,
  onChange,
  disabled = false,
  loading,
  labelClassName,
  containerClassName,
  innerClassName,
  tip,
  hideDefaultLabel = true,
}: IToggleSwitchProps) => {
  const defaultLabel = checked ? "On" : "Off";

  return (
    <Tooltip content={tip || ""} disabled={!tip}>
      <div
        className={cn(
          "flex items-center justify-center align-center group",
          className
        )}
      >
        <div className={cn("w-full max-w-xs mx-auto", containerClassName)}>
          <Switch.Group
            as="div"
            className={cn("flex items-center space-x-4", innerClassName)}
          >
            {label ? (
              <Switch.Label
                className={cn(
                  "whitespace-nowrap body-100 cursor-pointer",
                  disabled && "cursor-auto",
                  labelClassName
                )}
              >
                {label}
              </Switch.Label>
            ) : (
              <>
                {!hideDefaultLabel && (
                  <Switch.Label
                    className={cn(
                      "whitespace-nowrap body-100 cursor-pointer",
                      disabled && "cursor-auto",
                      labelClassName
                    )}
                  >
                    {defaultLabel}
                  </Switch.Label>
                )}
              </>
            )}
            {loading ? (
              <div className="flex items-center w-[52px] h-6 justify-center">
                <BigSpinner className="w-5 h-5" />
              </div>
            ) : (
              <Switch
                as="button"
                checked={checked}
                onChange={onChange}
                onClick={(e) => e.stopPropagation()}
                disabled={disabled}
                className={cn(
                  "group-hover:border-cta-600 group-hover:!bg-indigo-50 bg-gray-60 relative inline-flex flex-shrink-0 h-6 transition-colors duration-200 ease-in-out rounded-full shadow-inner cursor-pointer w-11 focus:outline-none",
                  checked && "!bg-indigo-70",
                  disabled &&
                    "!bg-gray-400 cursor-auto group-hover:!bg-gray-400"
                )}
              >
                {({ checked }) => (
                  <span
                    className={cn(
                      `inline-block transition-all w-5 h-5 duration-200 ease-in-out transform rounded-full self-center bg-white`,
                      checked ? "translate-x-5" : "translate-x-1"
                    )}
                  />
                )}
              </Switch>
            )}
            {!!rightLabel && (
              <Switch.Label
                className={cn(
                  "whitespace-nowrap body-100 cursor-pointer",
                  disabled && "cursor-auto"
                )}
              >
                {rightLabel}
              </Switch.Label>
            )}
          </Switch.Group>
        </div>
      </div>
    </Tooltip>
  );
};

export default ToggleSwitch;
