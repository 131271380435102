
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import "../styles/globals.scss";
import "../styles/date-range.scss";
import "../styles/skeleton-loading.scss";
import "../styles/linear-loading.scss";
import "../styles/multiRangeSlider.scss";
import type { AppProps } from "next/app";
import { AllContexts } from "contexts";
import "utils/customValidations";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { useNetwork } from "hooks/useNetwork";
import ErrorBoundary from "components/organisms/ErrorBoundary";
import { useEffect } from "react";
import Modal from "react-modal";
import { DrawerRootId, FIREBASE_URL } from "utils/constants";
import AdminRouteProtector from "components/organisms/AdminRouteProtector";
import Icon from "components/atoms/Icon";
import CloseIcon from "public/icons/close.svg";
import Script from "next/script";
import ArticleDrawer from "components/organisms/Articles/ArticleDrawer";
import Head from "next/head";

import { useState } from "react";
import { getIpLocation } from "utils/getIpLocation";
import { getIsChinaLocation } from "utils/getIsChinaLocation";
import fetchIntercept from "fetch-intercept";
import { useRouter } from "next/router";
import ToggleSwitch from "components/atoms/ToggleSwitch";
import useTranslation from "next-translate/useTranslation";
import { isProduction, isStaging } from "utils/checkEnv";
import { useTutorialStateStore } from "stores/TutorialStateStore";
import TutorialDrawer from "components/molecules/Tutorial/TutorialDrawer";

const CloseButton = () => (
  <div className="flex items-center justify-center pl-4">
    <div className="w-3.5 h-3.5">
      <Icon component={CloseIcon} viewBox="0 0 12 12" width={14} height={14} />
    </div>
  </div>
);

const env = process.env.NEXT_PUBLIC_ENV;
const CLARITY_KEY = process.env.NEXT_PUBLIC_CLARITY_KEY;
const BASE_URL = process.env.NEXT_PUBLIC_API_HOST;

function App({ Component, pageProps }: AppProps) {
  const { t } = useTranslation("common");
  const [ipChecked, setIpChecked] = useState<boolean>(false);
  const [isChina, setIsChina] = useState<boolean>(false);
  const [showAuthToggle, setShowAuthToggle] = useState<boolean>(false);
  const router = useRouter();

  const { tutorialOpen, handleCloseTutorial } = useTutorialStateStore(
    (state) => state
  );

  useNetwork();

  const isAuth =
    router?.isReady &&
    (router.asPath.includes("/auth/login") ||
      router.asPath.includes("/auth/signup"));

  useEffect(() => {
    Modal.setAppElement("body");
  }, []);

  // checks ip location
  useEffect(() => {
    const getAndSetIsChina = async () => {
      const res = await getIpLocation();

      if (res?.ipLocation && getIsChinaLocation(res.ipLocation)) {
        setIsChina(true);
        setShowAuthToggle(true);
      }

      setIpChecked(true);
    };

    getAndSetIsChina();
  }, []);

  // handles China firebase requests
  useEffect(() => {
    return fetchIntercept.register({
      request: function (url, config) {
        if (isChina && url.includes(FIREBASE_URL)) {
          url = url.replace(FIREBASE_URL, `${BASE_URL}/firebase-proxy`);
        }

        return [url, config];
      },
    });
  }, [isChina]);

  const { title, logo } = pageProps;

  const preparedProps = { ...pageProps, isChina };

  if (!ipChecked) {
    return null;
  }

  return (
    <>
      <Head>
        <title>
          {env && env !== "production" ? `[${env}] ` : ""}
          {title ? title : "jellistudio"}
        </title>
        <meta content="website" property="og:type" />
        <meta property="og:site_name" content="jellistudio" key="ogsitename" />
        <meta
          property="og:title"
          content={title ? title : "jellistudio"}
          key="ogtitle"
        />
        <meta name="twitter:title" content={title ? title : "jellistudio"} />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        ></meta>
        <link rel="icon" type="image/jpg" href={logo || "/favicon.jpg"} />
        <link rel="apple-touch-icon" href={logo || "apple-touch-icon.png"} />
        <link
          rel="icon"
          href={logo || "/favicon.svg"}
          sizes="any"
          type="image/svg+xml"
        />
      </Head>
      <AllContexts>
        {env === "production" && (
          <>
            <Script id="ms-clarity" strategy="afterInteractive">
              {`(function(c,l,a,r,i,t,y){
              console.log('clarity init')
              c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
              t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
              y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
              })(window, document, "clarity", "script", "${CLARITY_KEY}");`}
            </Script>
            <Script>
              {`(function(w,d,s,l,i){
              console.log('gtm init')
              w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','GTM-NGWDJT8H');`}
            </Script>
          </>
        )}

        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar
          theme="light"
          closeButton={CloseButton}
          className={"!w-auto"}
          limit={2}
        />
        <ErrorBoundary>
          <div className="relative">
            {isAuth && showAuthToggle && (
              <div className="mb-4 absolute z-50 top-6 right-6 bg-white p-2 rounded-md">
                <p className="body-100 mb-2">{t("china_ip_detected")}</p>
                <ToggleSwitch
                  label={t("china_auth_enabled")}
                  checked={isChina}
                  onChange={(val: boolean) => setIsChina(val)}
                  containerClassName="mx-0 items-start"
                  className="!justify-start"
                />
              </div>
            )}
          </div>
          <Component {...preparedProps} />
          <AdminRouteProtector />
          <TutorialDrawer open={tutorialOpen} onClose={handleCloseTutorial} />
          <div id={DrawerRootId}></div>
        </ErrorBoundary>
      </AllContexts>
    </>
  );
}

const __Page_Next_Translate__ = App;


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: true,
// @ts-ignore
      
// @ts-ignore
    });
// @ts-ignore
  