import { useImage } from "react-image";
import SkeletonLoading from "./SkeletonLoading";

interface ImageProps {
  id?: string;
  imgUrl?: string;
  imgUrls?: string[];
  width?: number | string;
  height?: number | string;
  className?: string;
  isLoadingImage?: boolean;
  invisibleNoImage?: boolean;
}

const Image = ({
  id,
  imgUrl,
  imgUrls,
  width,
  height,
  className,
  isLoadingImage = false,
  invisibleNoImage = false,
}: ImageProps) => {
  const { src, isLoading } = useImage({
    srcList: [
      ...(imgUrl ? [imgUrl] : imgUrls || []),
      "/images/no_image_53x64.png",
    ],
    useSuspense: false,
  });

  const isNoImage = src === "/images/no_image_53x64.png";

  return !isLoading && !isLoadingImage ? (
    isNoImage && invisibleNoImage ? null : (
      <img
        id={id}
        src={src}
        height={isNoImage ? 64 : height}
        width={isNoImage ? 53 : width}
        className={className}
        style={
          isNoImage
            ? {
                height: 64,
                width: 53,
              }
            : {}
        }
      />
    )
  ) : (
    <SkeletonLoading className={className} width={width} height={height} />
  );
};

export default Image;
