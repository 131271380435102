const colors = require("tailwindcss/colors");

module.exports = {
  content: [
    "./pages/**/*.{js,ts,jsx,tsx}",
    "./components/**/*.{js,ts,jsx,tsx}",
    "./businessComponents/**/*.{js,ts,jsx,tsx}",
    "./node_modules/react-tailwindcss-datepicker/dist/index.esm.js",
  ],
  theme: {
    extend: {
      transitionProperty: {
        "max-height": "max-height",
        "max-width": "max-width",
        height: "height",
        background: "background-color",
      },
      animation: {
        loading: "loading-bar 1.5s linear infinite",
      },
      lineClamp: {
        12: "12",
        16: "16",
      },
      fontSize: {
        8: "8px",
        xxs: "10px",
        13: "13px",
        15: "15px",
        32: "32px",
        40: "40px",
      },
      boxShadow: {
        hover: "0 8px 36px rgba(0, 0, 0, 0.1)",
      },
      dropShadow: {
        top: "0px 8px 36px rgba(0, 0, 0, 0.1)",
        footer: "0px 4px 20px rgba(0, 0, 0, 0.1)",
      },
      width: {
        19: "4.75rem",
        67: "16.75rem",
      },
      maxHeight: {
        "max-modal": "90vh",
      },
      keyframes: {
        bouncedelay: {
          "0%, 80%, 100%": {
            transform: "scale(0)",
          },
          "40%": {
            transform: "scale(1.0)",
          },
        },
        "loading-bar": {
          "0%": { transform: "translateX(-100%)" },
          "100%": { transform: "translateX(200%)" },
        },
      },
      padding: {
        30: "120px",
      },
      colors: {
        border: {
          default: "#E8EAED",
          normal: "#D9D9D9",
          hover: "#BFBFBF",
          active: "#AEA4FD",
          error: "#FF4F42",
          focus: "#1F1F1F",
          input: {
            normal: "#F5F5F5",
            disable: "#D9D9D9",
            focus: "#1F1F1F",
            hover: "#F5F5F5",
            error: "#FF4D4F",
          },
          checkbox: {
            noraml: "#D9D9D9",
            hover: "#1F1F1F",
            focus: "#1F1F1F",
            error: "#FF4D4F",
          },
          radio: {
            normal: "#D9D9D9",
            hover: "#1F1F1F",
            focus: "#1F1F1F",
            select: "#1F1F1F",
          },
        },
        cta: {
          500: "#503BF5",
          600: "#341BF9",
          700: "#1F0CB6",
        },
        black: "#000000",
        red: {
          10: "#FFC8C5",
          30: "#FFA39E",
          50: "#FF4D4F",
          70: "#CF1322",
          90: "#820014",
        },
        green: {
          10: "#F6FFED",
          30: "#B7EB8F",
          50: "#73D13D",
          70: "#389E0D",
          90: "#135200",
          100: "#E0FFE5",
          200: "#B6FFC2",
          300: "#9AF2A8",
          350: "#80E290",
          400: "#70F084",
          500: "#4BCF60",
          600: "#00DA23",
          650: "#00C620",
          700: "#11A328",
          800: "#007513",
        },
        orange: {
          10: "#FFE6BD",
          30: "#FFD591",
          50: "#FFA940",
          70: "#D46B08",
          75: "#FFF7E6",
          90: "#873800",
          100: "#FEF3D8",
          200: "#F5DFA6",
          300: "#F9DB8D",
          350: "#ECC766",
          400: "#FFCF54",
          700: "#A77800",
          800: "#694C00",
        },
        white: "#FFFFFF",
        danger: {
          100: "#F3CDCC",
          200: "#EDACAB",
          600: "#AC211A",
          800: "#780500",
          ...colors.red,
        },
        gray: {
          10: "#FAFAFA",
          15: "#F8F8F8",
          20: "#F5F5F5",
          30: "#F0F0F0",
          40: "#D9D9D9",
          50: "#BFBFBF",
          60: "#8C8C8C",
          70: "#595959",
          80: "#434343",
          90: "#262626",
          100: "#F3F3F3",
          200: "#E3E5E7",
          300: "#CACDD0",
          400: "#B9BBBD",
          500: "#A3A4A7",
          600: "#7F8182",
          700: "#626263",
          800: "#4D4E4F",
          900: "#323233",
          950: "#1F1F1F",
        },

        purple: {
          100: "#F2EAFD",
          200: "#E0CDFC",
          300: "#CDAFF6",
          350: "#9C76D1",
          400: "#AC77F6",
          500: "#8C42F6",
          600: "#7A24F4",
          700: "#4B0CA4",
          800: "#300073",
        },
        cobalt: {
          25: "#F6F6F9",
          50: "#F4F5FA",
          75: "#E9EBF2",
          100: "#E6E8F3",
          200: "#D7DAE7",
          300: "#8B91AF",
          400: "#5A5F79",
          500: "#43475B",
        },

        yellow: {
          10: "#FCF2D4",
          100: "#FFFBE3",
          200: "#FFF6BE",
          250: "#FDFFA6",
          300: "#F9ECA1",
          400: "#FBE35C",
          500: "#ECD034",
          600: "#F3BD42",
          800: "#625300",
          ...colors.yellow,
        },
        indigo: {
          ...colors.indigo,
          10: "#EBE8FE",
          20: "#D6D1FE",
          30: "#AEA4FD",
          40: "#9A8DFC",
          50: "#715FFB",
          70: "#341BF9",
          250: "#CFCAF9",
          350: "#968BEB",
        },
        "deep-blue": {
          100: "#D9DEF9",
          200: "#C3CBFA",
          300: "#9DADFC",
          350: "#6B7DD7",
          400: "#556EEF",
          500: "#3652E4",
          700: "#01299F",
          800: "#00175C",
        },
        "light-blue": {
          100: "#D6F5FF",
          150: "#C9EBFF",
          200: "#BEEAF8",
          300: "#9DE3F9",
          350: "#77CAE4",
          400: "#67D1F2",
          700: "#0083A8",
          800: "#004356",
        },
        turquoise: {
          100: "#DDFDFB",
          200: "#BBF3F0",
          300: "#9AF3EF",
          350: "#80E0DB",
          400: "#72F9F1",
          500: "#2CE3DA",
          600: "#00E3D7",
          650: "#02BCB2",
          700: "#00A69D",
          800: "#007872",
        },
        "poison-green": {
          100: "#DCFFF3",
          200: "#B5FEE4",
          350: "#80EAC4",
          400: "#6FFBC9",
          700: "#01A86C",
          800: "#00602C",
        },
        "poison-gren": {
          300: "#9CFCDA",
        },
        "light-green": {
          100: "#F2FFE3",
          200: "#DFFBBC",
          300: "#D7FFA4",
          350: "#C0EA8C",
          400: "#B8F76B",
          700: "#61AD01",
          800: "#2A6D00",
        },
        "lime-yellow": {
          100: "#FEFFCA",
          200: "#F5F6B1",
          300: "#F1F396",
          350: "#EBED7D",
          700: "#7D7E00",
          800: "#515200",
        },
        "red-orange": {
          100: "#FCEEDE",
          200: "#F7CEB7",
          300: "#F0B696",
          350: "#F3A275",
          400: "#FBAD51",
          450: "#F28142",
          550: "#F1712A",
          600: "#F35D09",
          650: "#EB5400",
          700: "#D17D19",
          800: "#772B00",
        },
        magenta: {
          100: "#F0E1FE",
          200: "#F0CDFC",
          350: "#CF7EEB",
          400: "#D062F7",
          450: "#C748F2",
          550: "#BF20F5",
          600: "#B50DEF",
          650: "#A405DB",
          700: "#9304C5",
          800: "#52006F",
        },
        rose: {
          200: "#FCCDEF",
          700: "#B1009F",
          800: "#720022",
        },
        "deep-rose": {
          200: "#FCCDDB",
          800: "#700021",
        },
        blue: {
          10: "#E6F4FF",
          30: "#91CAFF",
          50: "#4096FF",
          70: "#0958D9",
          90: "#002C8C",
          200: "#CDDFFC",
          800: "#003386",
        },
        "cta-alt": {
          1: "#EC5A53",
          2: "#D94174",
        },
        typography: {
          title: "#1F1F1F",
          hint: "#8C8C8C",
          body: {
            normal: "#434343",
            second: "#D8D8D8",
            subtle: "#8C8C8C",
            disabled: "#D9D9D9",
          },
          "body-1": "#42474D",
          headline: "#1F1F1F",
          tag: {
            subtle: "#595959",
            focus: "#595959",
            passive: "#545B63",
          },
          text: {
            primary: "#161616",
            link: "#341BF9",
          },
          tab: {
            subtle: "#595959",
            passive: "#545B63",
          },
        },
        layer: {
          tokens: {
            "02": "#E0E0E0",
          },
        },
        title: "#1F1F1F",
        body: "#434343",
        "body-subtitle": "#8C8C8C",
        subtitle1: "#545B63",
        subtitle2: "#42474D",
        table: {
          header: "#1F1F1F",
          body: "#595959",
        },
        heading: "#1A1D21",
        "custom-color-1": "#E8EAED",
        dark: "#111112",
      },
      screens: {
        xxs: "320px",
        xs: "540px",
        sm: "640px",
        md: "768px",
        "2md": "978px",
        lg: "1024px",
        "2lg": "1180px",
        xl: "1280px",
        "2xl": "1366px",
        "2.5xl": "1440px",
        "3xl": "1536px",
        "4xl": "1680px",
        "5xl": "1920px",
      },
      fontWeight: {
        light: 200,
        normal: 300,
        medium: 400,
        semibold: 500,
        bold: 600,
        extrabold: 700,
        black: 800,
        default: 300,
      },
    },
    namedGroups: ["1", "2"],
  },
  plugins: [
    require("tailwindcss-animation-delay"),
    require("@tailwindcss/line-clamp"),
    require("tailwindcss-named-groups"),
  ],
  darkMode: "selector",
};
