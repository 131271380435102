import { AxiosResponse } from "axios";
import { TvChannel } from "models/TvChannel";
import AbstractApi from "./AbstractApi";
import isNil from "lodash/isNil";
import { TVGarment } from "models/TvGarment";
import { QueryClient } from "react-query";

export interface ILayerImage {
  url: string;
  coordinates: number[];
  dimensions: number[];
  rotation: number;
}
export enum TPatternImageType {
  WRAP_AROUND = "wrap-around",
  TILE = "tile",
}

export interface IPatternImage {
  url: string;
  transparency: number;
  width: number;
  height: number;
  type: TPatternImageType;
}

export interface ITvCreateRevisionPayload {
  channelId: number;
  parentId?: number;
  keywordSet?: ITvGarmentKeyword[];
  keywordSetId?: number;
  rootGarmentId?: number;
  mask?: string | null;
  maskPrompt?: string | null;
  sketch?: string | null;
  layerImage?: ILayerImage;
}

export interface ITvUpdateRevisionPayload {
  keywordSet?: ITvGarmentKeyword[];
  mask?: string | null;
  maskPrompt?: string | null;
  sketch?: string | null;
  patternImage?: IPatternImage | null;
}

export interface ITvStatistics {
  creditsLeft: number;
  creditsTotalSpentCurrentPeriod: number;
  creditsMaxAvailableCurrentPeriod: number;
}

export interface ITvGarmentKeyword {
  style: string;
  value: string;
  origin?: string;
  preselected?: boolean;
}

export interface ITvChannelPayload {
  name: string;
  tvProjectId?: number;
  keywordSet?: ITvGarmentKeyword[];
  revisionGarmentId?: number;
  imageSample?: string;
  mask?: string | null;
  sketch?: string | null;
  maskPrompt?: string | null;
}

export type GenerationPlatform =
  | "openai"
  | "dreamstudio"
  | "custom"
  | "mock"
  | "replicate";

export interface IGarmentPayload {
  channelId: number;
  keywordSet?: ITvGarmentKeyword[];
  keywordSetId?: number;
  parentId?: number;
  samples: number;
  platform: GenerationPlatform;
  engineVersion?: string;
  tvGarmentRevisionId?: number;
  mask?: string | null;
  sketch?: string | null;
  maskPrompt?: string | null;
  image?: string;
  generationParams?: Record<string, number>;
  layerImage?: ILayerImage;
  patternImage?: IPatternImage | null;
}

export interface IInPaintingPayload {
  formData: FormData;
}

export interface IUpscaleImagePayload {
  id: number;
  platform?: GenerationPlatform;
  engineVersion?: string;
}

export default class TvChannelsApi extends AbstractApi {
  constructor(
    userToken: string | null,
    teamId: number | null,
    auth: any,
    queryClient: QueryClient,
    portalId?: number
  ) {
    super(userToken, teamId, auth, queryClient, portalId);
  }

  async getTvChannels(
    params: Record<string, any>
  ): Promise<AxiosResponse<TvChannel[]>> {
    return await this.client.get(`/tv-channels`, { params });
  }

  async getTvChannelById(id: number) {
    return await this.client.get(`/tv-channels/${id}`).then(({ data }) => data);
  }

  async createTvChannel(payload: ITvChannelPayload) {
    return await this.client.post(`/tv-channels`, payload);
  }

  async updateTvChannel(
    collectionId: number,
    payload: Partial<ITvChannelPayload>
  ) {
    return await this.client
      .patch(`/tv-channels/${collectionId}`, payload)
      .then(({ data }) => data);
  }

  async deleteTvChannel(id: number) {
    return await this.client.delete(`/tv-channels/${id}`);
  }

  async moodboard(channelId: number) {
    return await this.client.get(`/tv-channels/${channelId}/moodboard`);
  }

  async addToMoodboard(channelId: number, garmentId: number) {
    return await this.client.post(`/tv-channels/${channelId}/moodboard`, {
      garmentId,
    });
  }

  async removeFromMoodboard(channelId: number, garmentId: number) {
    return await this.client.delete(
      `/tv-channels/${channelId}/moodboard/${garmentId}`
    );
  }

  async revisions(channelId: number) {
    return await this.client.get(`/tv-channels/${channelId}/revisions`);
  }

  async createGarment(payload: IGarmentPayload) {
    return await this.client.post("/tv-garments", payload);
  }

  async getGarments({
    channelId,
    revisionGarmentId,
    page,
  }: {
    channelId: number;
    revisionGarmentId?: number;
    page?: number;
  }) {
    let url = `/tv-garments?filter.channelId=${channelId}&sortBy=createdat:ASC&limit=100${
      !isNil(page) ? `&page=${page}` : ""
    }`;

    if (revisionGarmentId !== undefined) {
      url += `&filter.tvGarmentRevisionId=${revisionGarmentId}`;
    }

    return await this.client.get(url).then((data) => {
      return data;
    });
  }

  async getGarment(id: number): Promise<TVGarment> {
    return await this.client.get(`/tv-garments/${id}`).then(({ data }) => data);
  }

  async uploadImage(formData: FormData) {
    return await this.client.post(`/tv-garments/upload`, formData);
  }

  async queueAnalytics(params: {
    start?: string;
    end?: string;
    limit?: number;
  }) {
    return await this.client.get(`/queue-analytics`, { params });
  }

  async getTvLimits(): Promise<ITvStatistics> {
    return await this.client.get(
      "/tv-image-credit-histories/credits-statistic/team-owner"
    );
  }

  async getGarmentParents(id: number): Promise<TVGarment[]> {
    return await this.client.get(`/tv-garments/${id}/parents`);
  }

  async updateGarment({
    id,
    payload,
  }: {
    id: number;
    payload: Partial<TVGarment>;
  }): Promise<{ data: TVGarment }> {
    return await this.client.patch(`/tv-garments/${id}`, payload);
  }

  async createRevision(payload: ITvCreateRevisionPayload) {
    return await this.client.post("/tv-garment-revisions", payload);
  }

  async updateRevision(id: number, payload: ITvUpdateRevisionPayload) {
    return await this.client.patch(`/tv-garment-revisions/${id}`, payload);
  }

  async getRevisions(channelId: number) {
    return await this.client.get(
      `/tv-garment-revisions?filter.channelId=${channelId}`
    );
  }

  async getRevisionParents(revisionId: number) {
    return await this.client.get(`/tv-garment-revisions/${revisionId}/parents`);
  }

  async upscaleGarmentImage(payload: IUpscaleImagePayload) {
    const { id, ...rest } = payload;
    return await this.client.post(`/tv-garments/${id}/upscale`, rest);
  }

  async getTvLimitsByUser(): Promise<ITvStatistics> {
    return await this.client.get(
      "/tv-image-credit-histories/credits-statistic/my"
    );
  }
}
