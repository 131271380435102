import Portal from "models/Portal";
import { QueryClient } from "react-query";
import {
  IPaginateParams,
  ITeamStats,
  PaginatedResponse,
  TeamMember,
} from "types";
import AbstractApi from "./AbstractApi";

export interface ICreatePortalPayload {
  portalUrlSlug: string;
  name: string;
  description?: string;
  logo?: string | null;
  signUpCode: string;
}

export interface IPortalBlacklistItem {
  email: string;
  id: number;
  portalId: number;
}

export default class PortalApi extends AbstractApi {
  constructor(
    userToken: string | null,
    teamId: number | null,
    auth: any,
    queryClient: QueryClient,
    portalId?: number
  ) {
    super(userToken, teamId, auth, queryClient, portalId);
  }

  async getPortals({ filters, ...rest }: any) {
    let url = "/portals";
    if (filters && filters.length) {
      filters.forEach(
        (value: string, index: number) =>
          (url += !index
            ? `?filter.keywordSet.keywordSet.value=${value}`
            : `&filter.keywordSet.keywordSet.value=${value}`)
      );
    }

    return await this.client.get(url, { params: rest });
  }

  async createPortal(payload: ICreatePortalPayload): Promise<Portal> {
    return await this.client.post("/portals/my", payload);
  }

  async editPortal(
    payload: Partial<ICreatePortalPayload> & { id: number }
  ): Promise<Portal> {
    const { id, ...rest } = payload;
    return await this.client.patch(`/portals/my/${id}`, rest);
  }

  async deletePortal(id: number) {
    return await this.client.delete(`/portals/my/${id}`);
  }

  async getPortalSamples({ filters, ...rest }: IPaginateParams) {
    let url = "/portals/current/samples";
    if (filters) {
      Object.values(filters)
        .filter((selectValue) => !!selectValue.value)
        .forEach(
          (selectValue, index) =>
            (url += !index
              ? `?filter.keywordSet.keywordSet.value=${selectValue.name}`
              : `&filter.keywordSet.keywordSet.value=${selectValue.name}`)
        );
    }

    return await this.client.get(url, { params: rest });
  }

  async getPortalUsersStatistics(params: IPaginateParams, sortString: string) {
    let query = "";
    Object.entries(params).forEach(([key, value], index) => {
      if (index) {
        query += `&${key}=${value}`;
      } else {
        query += `${key}=${value}`;
      }
    });

    query += sortString;
    return await this.client.get(`/users/portal-statistic?${query}`);
  }

  async getPortalUserTeammates(userId: number, params: IPaginateParams) {
    return await this.client.get(`/users/${userId}/invited-users`, { params });
  }

  async getPortalSignUpCode() {
    return await this.client.get("/portals/my/own");
  }

  async getPortalMaintainers(params: IPaginateParams) {
    return await this.client.get("/portals/current/users/maintainers", {
      params,
    });
  }

  async getPortalByPortalUrlSlug(portalUrlSlug: string): Promise<Portal> {
    return await this.client.get(`/portals/get-by-domain/${portalUrlSlug}`);
  }

  async getUserPortals(params: Record<string, any>) {
    return await this.client.get("/portals/my", { params });
  }

  async deletePortalUser(userId: number) {
    return await this.client.delete(`/portals/current/users/${userId}`);
  }

  async getPortalBlacklist(
    params: IPaginateParams
  ): Promise<PaginatedResponse<IPortalBlacklistItem>> {
    return await this.client.get("/portals/current/users/blacklist", {
      params,
    });
  }

  async addEmailToBlacklist(email: string) {
    return await this.client.post("/portals/current/users/blacklist", {
      email,
    });
  }

  async deleteBlacklistItem(id: number) {
    return await this.client.delete(`/portals/current/users/blacklist/${id}`);
  }

  async getPortalBrandTeams(params: IPaginateParams) {
    return await this.client.get("/portals/current/teams/brands", { params });
  }

  async getPortalUserTeamsStatistic(
    userId: number,
    params: IPaginateParams
  ): Promise<PaginatedResponse<ITeamStats>> {
    return await this.client.get(
      `/portals/current/users/${userId}/teams/statistics`,
      { params }
    );
  }

  async getPortalTeamMembers(
    teamId: number,
    params: IPaginateParams
  ): Promise<PaginatedResponse<TeamMember>> {
    return await this.client.get(`/portals/current/teams/${teamId}/members`, {
      params,
    });
  }

  async getPortalTeamGuests(
    teamId: number,
    params: IPaginateParams
  ): Promise<PaginatedResponse<TeamMember>> {
    return await this.client.get(`/portals/current/teams/${teamId}/guests`, {
      params,
    });
  }
}
